import React, {useState, useEffect} from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import LanguageContext from "../context/LanguageContext";
import NewsComponent from '../components/News/News.js';
import Seo from "../components/seo";
const NewsPage = ({ data }) => {

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
      setHasMounted(true);
  }, []);

  if(!hasMounted) return null;

  return (
    <LanguageContext.Consumer>
      {language => (
        <Layout>
           <Seo title="News" lang={language.english ? "en" : 'ga'} />
          <NewsComponent language={language} news={data.allWpNews}/>
        </Layout>
      )}
    </LanguageContext.Consumer>
    )}

export default NewsPage;

export const pageQuery = graphql`
  query {
  allWpNews(sort: {fields: date, order: DESC }){
		nodes{
			title
      date
      news_acf{
        titleIrish
        caption
        captionIrish
        contentirish
        content
        heroBackgroundColour
        heroFontColorBlack
				heroImage {
          altText
          altTextIrish{
          irishAlt}
          localFile{
            childImageSharp {
            gatsbyImageData( 
              placeholder: BLURRED
            layout: FULL_WIDTH
            )
          }
         }
				}
      }
    }
  }
 }
`
