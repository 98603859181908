
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
   card:{
    borderRadius:'15px',
    border:'1px solid #CED0E0',
    height:'100%', 
    boxShadow: "none", 
    '&:hover': {
        cursor:'pointer',
        backgroundColor:'#EEEDF4',
        border:'1px solid rgba(89,78,148,0.6)',
    },
   }
});

export default useStyles;