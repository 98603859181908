import React, { useEffect, useRef } from 'react';
import { Container, Typography, Grid, Box  } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Link, navigate } from 'gatsby';
import useStyles from './styles';
import { GatsbyImage, getImage } from "gatsby-plugin-image";


const NewsComponent = ({language, news}) => {

  const classes = useStyles();

  const isInitialMount = useRef(true);

  useEffect(() => {   
    if (isInitialMount.current) {
      isInitialMount.current = false;
   } else {
    if(language.english){
      navigate("/news")
     }else{
      navigate("/nuacht")
     }
   }
  },[language.english]);

    return (
        <Grid container style={{backgroundColor:'#E6E4EF',paddingBottom:'100px'}} role="main" aria-label="news">
            <Container maxWidth="xl">
                <Grid container>
                    <Grid item xs={12} pt={14} pb={4} display="flex" justifyContent="center">
                        <Typography variant="heading" component="h1" color="#594E94">{language.english ? 'News' : 'Nuacht'}</Typography>
                    </Grid>
                {news.nodes.map((item, index) => {
                    let date = new Date(item.date).toDateString();
                    let irishDate;
                    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
                    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                    const daysGA = ['Lua', 'Mha', 'Che', 'Dea', 'Aoi', 'Sat', 'Dom'];
                    const monthsGA = ['Ean', 'Fea', 'Mar', 'Aib', 'Bea', 'Mei', 'Lui', 'Lun', 'Mea', 'Dei', 'Sam', 'Nol'];
                    if(!language.english){
                      const day = date.substring(0,3);
                      const month = date.substring(4,7);
                      let newDay;
                      let newMonth;
                      for(let i = 0; i<days.length; i++){
                        if(day === days[i]){
                          newDay = daysGA[i];
                        }
                      }
                      for(let j = 0; j<months.length; j++){
                        if(month === months[j]){
                          newMonth = monthsGA[j];
                        }
                      }
                    let date1 = date.replace(`${day}`, `${newDay}`);
                    irishDate = date1.replace(`${month}`, `${newMonth}`);
                    }

              
                    
                    return(
                    <Grid key={index} item xs={12} sm={6} md={4} lg={3} p={2} pl={2} pr={2} mb={2}>
                    <Link id={index === 0 ? "main" : undefined} to={language.english ? `/news/${item?.title?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}` : `/nuacht/${item?.news_acf?.titleIrish.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}`} color="inherit" style={{textDecoration: 'none'}}>
                        <Card className={classes.card}>
                        <CardMedia height="140"><GatsbyImage style={{height:'200px'}} imgStyle={{borderRadius:'15px 15px 0px 0px'}} image={getImage(item.news_acf?.heroImage?.localFile?.childImageSharp)} alt={language.english ? item.news_acf?.heroImage?.altText : item.news_acf?.heroImage?.altTextIrish} /></CardMedia>
                        <Box p={1}>
                        <CardContent>
                            <Typography gutterBottom variant="cardheading2" component="h2">
                            {language.english ? item.title : item.news_acf.titleIrish}
                            </Typography>
                            <br />
                            <Typography variant="card2" color="text.secondary" >{language.english ? date.substring(4,15) : irishDate.substring(4,15)}</Typography>
                        </CardContent>
                        </Box>
                        </Card>
                    </Link>
                    </Grid>
                    )
                })}
            </Grid>
           
        </Container>

      </Grid>
    )
}

export default NewsComponent;